export const environment = {
    production: true,
    profile: 'dev',

    ic_dark_sandbox_word_logo: '/assets/icons/ic_dark_sandbox_word_logo.svg',
    ic_google_logo_circle: '/assets/icons/ic_google_logo_circle.svg',
    ic_microsoft_logo_circle: '/assets/icons/ic_microsoft_logo_circle.svg',
    ic_email_verification_logo: '/assets/icons/ic_email_verification_logo.svg',

    // product logos
    ic_sandbox_logo_circle: '/assets/icons/ic_sandbox_logo_circle.svg',

    // endpoints
    auth_services_endpoint: 'https://keq7mx11sf.execute-api.ap-south-1.amazonaws.com/dev',
    authentication_host: 'https://dev1-accounts.sandbox.co.in',
    org_quicko_cms_endpoint: 'https://d2l1ae64bvxp7e.cloudfront.net',
    reference_data_endpoint: 'https://d2l1ae64bvxp7e.cloudfront.net',
    sandbox_console_host: 'https://dev-console.sandbox.co.in/',
    cognito_domain: 'https://sandbox.auth.ap-south-1.amazoncognito.com',
    cognito_client_id: '21m7bl6mdm2uomv041c6pem5k6',
    login_url: 'https://dev1-accounts.sandbox.co.in/login'
};
