import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ReferenceDataService {

    private endpoint;

    constructor(private httpClient: HttpClient) {
        this.endpoint = environment.org_quicko_cms_endpoint;
    }

    getCountries() {
        const url = this.endpoint + '/api/org-quicko-country-flags?populate=*';
        return this.httpClient.get(url);
    }

}
