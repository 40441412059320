import { SocialAuthService } from '@abacritt/angularx-social-login';
import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ReactiveFormsModule, ValidatorFn } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { RiveModule } from 'ng-rive';
import queryString from 'query-string';
import { environment } from '../../../environments/environment';
import { User } from '../../bean/user';
import { AngularMaterialModule } from '../../modules/angular-material/angular-material.module';
import { AuthService } from '../../services/auth.service';
import { CacheService } from '../../services/cache.service';
import { EntitlementsService } from '../../services/entitlements.service';
import { SharedService } from '../../services/shared.service';
import { SnackbarService } from '../../services/snackbar.service';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.css'],
  standalone: true,
  imports: [AngularMaterialModule, RiveModule, CommonModule, RouterModule, ReactiveFormsModule]
})
export class PasswordComponent implements OnInit {

  signUpForm: FormGroup;
  saveForm: boolean = false;
  showPassword: boolean = false;
  showConfirmPassword: boolean = false;
  isValidationEnabled = false;
  passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_\-+=\|"':;<,>.?\/\\])[A-Za-z\d!@#$%^&*()_\-+=\|"':;<,>.?\/\\]{8,}$/;
  userCountryCode: string = "";
  redirectUrl: any;

  isLengthValid: boolean = false;
  isLowercaseValid: boolean = false;
  isUppercaseValid: boolean = false;
  isNumberValid: boolean = false;
  isSpecialCharacterValid: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private cacheService: CacheService,
    private router: Router,
    private entitlementsService: EntitlementsService,
    private socialAuthService: SocialAuthService,
    private snackBarService: SnackbarService,
    private authService: AuthService,
    private sharedService: SharedService,
    private formBuilder: FormBuilder
  ) {

    // Todo: Regex for email
    this.signUpForm = new FormGroup({
      email: new FormControl({ value: '', disabled: true }),
      first_name: new FormControl(''),
      last_name: new FormControl(''),
      business: new FormControl(''),
      phone: new FormControl(''),
      password: new FormControl(''),
      confirmPassword: new FormControl('')
    });

  }

  ngOnInit() {
    this.cacheService.getFormData().subscribe((user) => {
      this.signUpForm.patchValue(user)
      this.userCountryCode = user.country_code!;
    })

    this.activatedRoute.queryParams.subscribe((params) => {
      const inviteData = params['invite'];
      const email = params['email']

      if (inviteData) {
        const decodedData = queryString.parse(atob(inviteData));
        this.redirectUrl = decodedData['redirect']
        this.signUpForm.controls['email'].setValue(decodedData['email']);
      }

      else if (email) {
        this.signUpForm.controls['email'].setValue(email);

      }
    });

    this.signUpForm.controls['password'].valueChanges
      .subscribe((newPassword: string) => {
        this.isLengthValid = newPassword.length >= 8;
        this.isLowercaseValid = /[a-z]/.test(newPassword);
        this.isUppercaseValid = /[A-Z]/.test(newPassword);
        this.isNumberValid = /\d/.test(newPassword);
        this.isSpecialCharacterValid = /[!@#$%^&*()_\-+=\|"':;<,>.?\/\\]/.test(newPassword);
      });

  }

  customRequiredValidator(control: AbstractControl): ValidatorFn {
    return () => {
      if (!this.isValidationEnabled) {
        return null;
      }
      return control.value.trim() === '' ? { required: true } : null
    };
  }

  checkFormValidation() {
    this.signUpForm.get('password')!.markAsTouched()
    this.signUpForm.get('confirmPassword')!.markAsTouched()
    this.signUpForm.get('password')!.markAsDirty()
    this.signUpForm.get('confirmPassword')!.markAsDirty()

    // validates password field
    if (!this.signUpForm.controls['password'].value) {
      this.signUpForm.controls['password'].setErrors({ required: true })
    }
    else if (this.signUpForm.controls['password'].value && !this.passwordRegex.test(this.signUpForm.controls['password'].value)) {
      this.signUpForm.controls['password'].setErrors({ invalidPassword: true })
    }

    // validates confirm password field
    if (!this.signUpForm.controls['password'].value && !this.signUpForm.controls['confirmPassword'].value) {
      this.signUpForm.controls['confirmPassword'].setErrors({ required: true })
    }
    else if (this.signUpForm.controls['password'].value && this.signUpForm.controls['password'].value !== this.signUpForm.controls['confirmPassword'].value) {
      this.signUpForm.controls['confirmPassword'].setErrors({ match_error: true })
    }

  }

  onSignUp() {

    this.checkFormValidation();

    if (this.signUpForm.valid) {
      this.saveForm = true;
      let phone;
      if (this.signUpForm.controls['phone'].value) {
        phone = "+" + this.userCountryCode + this.signUpForm.controls['phone'].value
      }

      const user = new User();
      user.email = this.signUpForm.controls['email'].value;
      user.first_name = this.signUpForm.controls['first_name'].value;
      user.last_name = this.signUpForm.controls['last_name'].value;
      user.business = this.signUpForm.controls['business'].value;
      user.phone = phone;
      user.country_code = this.userCountryCode.toString();
      user.password = this.signUpForm.controls['password'].value;


      if (!this.redirectUrl) {
        this.redirectUrl = environment.sandbox_console_host
      }
      this.entitlementsService.signup(user, this.redirectUrl)
        .subscribe({
          next: (response: any) => {
            this.router.navigate(['signup', 'verification'])
            this.saveForm = false;
          },
          error: (err) => {
            if (err.error.message) {
              this.snackBarService.openSnackBar(err.error.message, undefined);
              this.saveForm = false;
            }
          }
        })


    }
  }
}
