import { Injectable } from '@angular/core';
import { CookieService as cookieService } from 'ngx-cookie-service';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class SessionCookieService {
  constructor(private cookieService: cookieService) { }

  public setCookie(key: string, value: string): void {
    if (environment.production) {
      this.cookieService.set(key, value, undefined, '/', '.sandbox.co.in', true, 'Lax');
    } else {
      this.cookieService.set(key, value, undefined, '/', 'localhost', true, 'Lax');
    }
  }

  public getCookie(key: string): string {
    return this.cookieService.get(key);
  }

  public deleteCookie(key: string): void {
    if (environment.production) {
      this.cookieService.delete(key, '/', '.sandbox.co.in', true, 'Lax');
    } else {
      this.cookieService.delete(key, '/', 'localhost', true, 'Lax');
    }
  }
}
