import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import * as _ from 'underscore';
import { SharedService } from './shared.service';
import { CacheService } from './cache.service';
import { User } from '../bean/user';

@Injectable({ providedIn: 'root' })
export class AuthService {

    authenticated: BehaviorSubject<any>;
    jwtHelper: JwtHelperService = new JwtHelperService();

    constructor(private cookieService: CookieService) {
        this.authenticated = new BehaviorSubject(this.isAuthenticated());
    }

    getAuthenticated() {
        return this.authenticated.asObservable();
    }

    setAuthenticated(isAuthenticated: any) {
        this.authenticated.next(isAuthenticated);
    }

    public setSessionToken(token: string): void {
        if (environment.production) {
            this.cookieService.set('SESSIONID', token, 1, '/', '.sandbox.co.in', true, 'Strict');
        } else {
            this.cookieService.set('SESSIONID', token, undefined, undefined, undefined, false, 'Strict');
        }
    }

    public setRefereshToken(token: string): void {
        if (environment.production) {
            this.cookieService.set('REFRESHSESSIONID', token, 1, '/', '.sandbox.co.in', true, 'Strict');
        } else {
            this.cookieService.set('REFRESHSESSIONID', token, undefined, undefined, undefined, false, 'Strict');
        }
    }

    public getToken(): string {
        return this.cookieService.get('SESSIONID');
    }

    public getUsername(): string | null {
        if (this.getToken() != null && this.getToken() != '') {
            let jwtToken = this.jwtHelper.decodeToken(this.getToken());
            return jwtToken['sub'];
        }
        return null;
    }

    public getAudience(): string | null {
        if (this.getToken() != null && this.getToken() != '') {
            let jwtToken = this.jwtHelper.decodeToken(this.getToken());
            return jwtToken['aud'];
        }
        return null;
    }

    public isAuthenticated(): boolean {
        try {
            if (this.getToken() != null) {
                let token = this.getToken().split('.');

                if (token && _.size(token) == 3 && this.jwtHelper.decodeToken(this.getToken()) &&
                    !this.jwtHelper.isTokenExpired(this.getToken())) {
                    return true;
                }
            }
        } catch (error) {
            console.log(error);
            return false;
        }

        return false;
    }

    getTokenExpiry(token: string): Date {
        return this.jwtHelper.getTokenExpirationDate(token)!;
    }

    isTokenExpired(token: string): boolean {
        return this.jwtHelper.isTokenExpired(token);
    }
}

@Injectable()
export class AuthErrorHandler implements ErrorHandler {
    authentication_host: string;
    constructor(private injector: Injector) {
        if (environment.production) {
            this.authentication_host = 'https://accounts.sandbox.co.in/';
        } else {
            this.authentication_host = 'http://localhost:4200/#/';
        }
    }

    handleError(error: any) {
        const router = this.injector.get(Router);
        if (error.rejection.status === 401 || error.rejection.status === 403) {
            window.location.href =
                this.authentication_host +
                'login' +
                '?redirectUrl=' +
                btoa(window.location.href);
        }
        throw error;
    }
}

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {

    constructor(private router: Router) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
                return event;
            }
            return;
        }, (err: any) => {

            if (err instanceof HttpErrorResponse) {
                if (err.status === 403) {
                    if (!this.router.url.toString().includes("/login"))
                        this.router.navigate(['login'], { queryParams: { redirectUrl: window.btoa(this.router.url) } });
                    return event;
                }
            }
            return
        }));
    }
}

@Injectable({providedIn: 'root'})
export class NotLoggedIn {

    redirectUrl: string | undefined;

    constructor(private authService: AuthService, private router: Router, private sharedService: SharedService) {
        this.sharedService.getRedirectUrl().subscribe((redirectUrl) => {
            this.redirectUrl = redirectUrl;
        })
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

        if (this.authService.isAuthenticated()) {
            if (this.redirectUrl) {
                window.location.href = window.atob(this.redirectUrl);
            }
            else {
                window.location.href = environment.sandbox_console_host;
            }
            return false;
        } else {
            return true;
        }
    }

}

@Injectable()
export class LoggedIn {
    authentication_host: string;
    constructor(private authService: AuthService, private router: Router) {
        if (environment.production) {
            this.authentication_host = 'https://accounts.sandbox.co.in/';
        } else {
            this.authentication_host = 'http://localhost:4200/';
        }
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (this.authService.isAuthenticated()) {
            return true;
        } else {
            window.location.href =
                this.authentication_host + '?redirectUrl=' + window.btoa(window.location.href);
            return false;
        }
    }
}


