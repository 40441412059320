import { Component, OnDestroy, OnInit } from '@angular/core';
import { CacheService } from '../../services/cache.service';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SnackbarService } from '../../services/snackbar.service';
import { AngularMaterialModule } from '../../modules/angular-material/angular-material.module';

@Component({
  selector: 'app-verification',
  templateUrl: './verification.component.html',
  styleUrls: ['./verification.component.css'],
  standalone:true,
  imports:[AngularMaterialModule]
})
export class VerificationComponent implements OnInit {
  email: string | undefined;
  private unsubscriber: Subject<void> = new Subject<void>();

  constructor(
    private cacheService: CacheService,
  ) {
    this.cacheService.getFormData().subscribe((user) => {
      this.email = user.email;
    });
  }

  ngOnInit() {
    this.cacheService.clearDataStream();
  }

}
