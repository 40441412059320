<div class="flex flex-row justify-center">
  <div class="flex flex-col bg-none min-h-screen z-10 flex-grow w-1/2 relative">
    <div class="z-30 mt-[96px] ml-[40px] w-[489px] flex flex-col gap-2">
      <p class="text-headline-semibold">Get API Keys within minutes
        and start testing</p>
      <p class="text-body-2-regular">Integrate with rich, developer friendly APIs </p>
    </div>
    <img src="assets/images/console_ss.png" alt="" class=" absolute z-30 w-2/3 h-[50vh] mt-[332px]">
    <img src="assets/images/tilted_background.png" alt="Tilted Rectangle" class="z-20 absolute w-[1602px] h-[875.18px]">
  </div>
  <div class="flex flex-col justify-between bg-white z-50 px-[32px] py-[40px] w-1/2">
    <!-- sandbox logo -->
    <div class="">
      <mat-icon svgIcon="ic_dark_sandbox_word_logo" class="w-[102px] h-4"></mat-icon>
    </div>


    <!-- password div -->
    <div class="flex flex-col mt-4">
      <div class="flex flex-col gap-[16px]">
        <button class="flex items-center  justify-center cursor-pointer w-[50px] -ml-1" [routerLink]="['../']">
          <mat-icon class="material-symbols-rounded text-navy-blue-600">chevron_left
          </mat-icon>
          <span class="text-note-medium text-navy-blue-600">
            Back
          </span>
        </button>
        <div class="flex flex-col gap-[8px]">
          <span class="text-subheading-1-semibold text-dark">Set your account password</span>
          <span class="text-caption-medium text-medium">Set a strong password to secure your account</span>
        </div>
      </div>
      <!-- password form div -->
      <div class="flex flex-col mt-[48px]">
        <form [formGroup]="signUpForm" (keyup.enter)="onSignUp()" class="flex flex-col gap-2">
          <!-- email form field -->
          <mat-form-field appearance="outline" class="w-[463px]">
            <mat-label>Email</mat-label>
            <input matInput formControlName="email" type="email">
          </mat-form-field>

          <!-- password form field -->
          <mat-form-field appearance="outline" class="w-[463px]">
            <mat-label>Password</mat-label>

            <input matInput placeholder="Password" formControlName="password"
              [type]="showPassword ? 'text' : 'password'" onpaste="return false" ondrop="return false"
              autocomplete="off">

            <!-- <mat-hint *ngIf="!signUpForm.controls['password'].errors" class="text-slate-500 text-xs w-[463px]">Must be
              at least
              8 characters long, have at least one lowercase character, one uppercase character, one number, and one
              special character
            </mat-hint> -->

            <!-- <mat-error [ngClass]="'custom-mat-error'" *ngIf="signUpForm.controls['password'].errors" class="w-[415px]">
              <mat-hint *ngIf="signUpForm.controls['password'].errors['required']" class="text-red-500 text-xs">
                Required
              </mat-hint>

              <mat-hint *ngIf="signUpForm.controls['password'].errors['invalidPassword']" class="text-red-500 text-xs">
                Must be at least 8 characters long, have at least one lowercase
                character, one uppercase character, one number, and one special character
              </mat-hint>
            </mat-error> -->

            <mat-icon matSuffix class="material-symbols-rounded text-light" (click)="showPassword=false"
              *ngIf="showPassword">
              visibility</mat-icon>
            <mat-icon matSuffix class="material-symbols-rounded text-light" (click)="showPassword=true"
              *ngIf="!showPassword">
              visibility_off</mat-icon>
          </mat-form-field>

          <div class="flex flex-col gap-3">
            <div class="flex flex-row gap-2"><img *ngIf="!isLengthValid" class="w-5 h-5"
                src="assets/icons/check_circle_disabled.svg" alt=""><img *ngIf="isLengthValid" class="w-5 h-5"
                src="assets/icons/check_circle_enabled.svg" alt="">
              <p class="text-caption-medium">Must be at least 8 characters long</p>
            </div>
            <div class="flex flex-row gap-2"><img *ngIf="!isLowercaseValid" class="w-5 h-5"
                src="assets/icons/check_circle_disabled.svg" alt=""><img *ngIf="isLowercaseValid" class="w-5 h-5"
                src="assets/icons/check_circle_enabled.svg" alt="">
              <p class="text-caption-medium">Must contain at least one lowercase character</p>
            </div>
            <div class="flex flex-row gap-2"><img *ngIf="!isUppercaseValid" class="w-5 h-5"
                src="assets/icons/check_circle_disabled.svg" alt=""><img *ngIf="isUppercaseValid" class="w-5 h-5"
                src="assets/icons/check_circle_enabled.svg" alt="">
              <p class="text-caption-medium">Must contain at least one uppercase character</p>
            </div>
            <div class="flex flex-row gap-2"><img *ngIf="!isNumberValid" class="w-5 h-5"
                src="assets/icons/check_circle_disabled.svg" alt=""><img *ngIf="isNumberValid" class="w-5 h-5"
                src="assets/icons/check_circle_enabled.svg" alt="">
              <p class="text-caption-medium">Must contain at least one number</p>
            </div>
            <div class="flex flex-row gap-2"><img *ngIf="!isSpecialCharacterValid" class="w-5 h-5"
                src="assets/icons/check_circle_disabled.svg" alt=""><img *ngIf="isSpecialCharacterValid" class="w-5 h-5"
                src="assets/icons/check_circle_enabled.svg" alt="">
              <p class="text-caption-medium">Must include at least one special character</p>
            </div>
          </div>


          <!-- confirm password form field -->
          <mat-form-field appearance="outline" class="w-[463px] mt-8">
            <mat-label>Confirm Password</mat-label>
            <input matInput placeholder="Password" formControlName="confirmPassword"
              [type]="showConfirmPassword ? 'text' : 'password'" onpaste="return false" ondrop="return false"
              autocomplete="off">

            <mat-error *ngIf="signUpForm.controls['confirmPassword'].errors" class="text-alert-error-900">
              <mat-hint *ngIf="signUpForm.controls['confirmPassword']!.errors!['required']"
                class="text-red-500 text-xs">
                Required
              </mat-hint>
              <mat-hint *ngIf="signUpForm.controls['confirmPassword'].errors['match_error']"
                class="text-red-500 text-xs">
                Password does not match
              </mat-hint>
            </mat-error>

            <mat-icon matSuffix class="material-symbols-rounded text-light" (click)="showConfirmPassword=false"
              *ngIf="showConfirmPassword">
              visibility</mat-icon>
            <mat-icon matSuffix class="material-symbols-rounded text-light" (click)="showConfirmPassword=true"
              *ngIf="!showConfirmPassword">
              visibility_off</mat-icon>
          </mat-form-field>

        </form>

        <!-- get started cta -->
        <div class="flex flex-wrap justify-start mt-[24px]">
          <ng-container *ngIf="!saveForm; else loading">
            <button class="text-body-1-regular text-white rounded-md min-w-[328px] bg-navy-blue-800 py-3 px-8"
              (click)="onSignUp()">Sign up</button>
          </ng-container>
        </div>
        <ng-template #loading>
          <button
            class="text-base font-medium text-white rounded-md min-w-[328px] bg-navy-blue-800 h-12 flex justify-center">
            <canvas riv="cta-loading" width="90" height="45">
              <riv-player name="loading-1" play></riv-player>
            </canvas>
          </button>
        </ng-template>
      </div>
    </div>

    <!-- sandbox copyright -->
    <div class="flex mt-8">
      <span class="text-caption-regular text-light">© 2023 Sandbox</span>
    </div>
  </div>
</div>