import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { RxFormBuilder, RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { RiveModule } from 'ng-rive';
import { Subject, takeUntil } from 'rxjs';
import { User } from '../../bean/user';
import { AngularMaterialModule } from '../../modules/angular-material/angular-material.module';
import { CacheService } from '../../services/cache.service';
import { EntitlementsService } from '../../services/entitlements.service';
import { OAuthService } from '../../services/oauth.service';
import { ReferenceDataService } from '../../services/reference-data.service';
import { SnackbarService } from '../../services/snackbar.service';
@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css'],
  standalone: true,
  imports: [AngularMaterialModule, RiveModule, CommonModule, RouterModule, ReactiveFormsModule, RxReactiveFormsModule]
})
export class SignupComponent implements OnInit {
  accountDetailForm: FormGroup;
  userCountryCode = 91;
  countryList: any;
  selectedCountry: any;
  saveForm: boolean = false;
  passwordView: boolean = false;
  verificationEmailView: boolean = false;
  showPassword: boolean = false;
  userObject: User | undefined;
  destroy$: Subject<boolean> = new Subject<boolean>();
  redirectUrl: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private cacheService: CacheService,
    private entitlementsService: EntitlementsService,
    private snackBarService: SnackbarService,
    private router: Router,
    private oauthService: OAuthService,
    private referenceDataService: ReferenceDataService,
    private fb: RxFormBuilder
  ) {

    this.accountDetailForm = this.fb.formGroup(new User());

    this.cacheService.getFormData().pipe(takeUntil(this.destroy$)).subscribe((user: User) => {
      this.accountDetailForm.patchValue(user)

      if (user && user.country_code)
        this.userCountryCode = parseInt(user.country_code)
    })
    this.referenceDataService.getCountries().pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      this.countryList = response['data'];
      this.selectedCountry = this.countryList.find(
        (c: any) => c.numeric_code === this.userCountryCode
      );
      if (!this.selectedCountry) {
        this.selectedCountry = this.countryList.find(
          (c: any) => c.numeric_code === 91
        );
      }
    });
  }

  ngOnInit() {

    this.activatedRoute.queryParams.subscribe((params: any) => {

      const inviteData = params['invite'];
      if (inviteData) {
        const decodedData = (atob(inviteData.split('=')[0])).split('&redirect=');
        this.redirectUrl = decodedData[1]
        const email = (((decodedData[0].split('email=')[1] as string)))
        this.accountDetailForm.controls['email'].setValue(email);
      }
    });
  }

  onGetStartedClick() {
    if (this.accountDetailForm.valid && this.userCountryCode) {

      this.userObject = this.accountDetailForm.getRawValue();
      this.userObject!.country_code = String(this.userCountryCode);
      this.cacheService.setFormData({
        ...this.userObject
      })
      this.saveForm = true;
      this.entitlementsService.checkUser(this.accountDetailForm.controls['email'].value).subscribe({
        next: (response) => {
          if (response.data) {
            this.snackBarService.openSnackBar("Email already in use", undefined);
            this.saveForm = false;
          }
          else {
            let encodedData;
            if (this.redirectUrl) {
              encodedData = btoa(`email=${encodeURIComponent(this.accountDetailForm.controls['email'].value)}&redirect=${this.redirectUrl}`)
              this.router.navigate(['signup', 'password'], {
                queryParams: {
                  "invite": encodedData
                }
              })
            }
            else {
              this.router.navigate(['signup', 'password'], {
                queryParams: {
                  "email": this.accountDetailForm.controls['email'].value
                }
              })
            }
          }
        },
        error: (err) => {
          if (err.error.message) {
            this.snackBarService.openSnackBar(err.error.message, undefined);
            this.saveForm = false;
          }
        }
      })
    }
  }

  loginWithGoogle() {
    this.saveForm = true;
    this.oauthService.authorizeGoogle();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}
