import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatOptionModule, MatPseudoCheckboxModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [CommonModule, RouterModule],
  declarations: [],
  exports: [
    MatCardModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatPseudoCheckboxModule,
    MatRadioModule,
    MatIconModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatOptionModule,
    MatSelectModule,
    MatSnackBarModule,
    MatProgressBarModule,
    MatDividerModule,
    MatDialogModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatChipsModule,
    MatMenuModule,
    MatExpansionModule,
    MatProgressBarModule,
    MatRippleModule,
    MatTooltipModule,
    MatTableModule,
    MatTabsModule,
    MatCheckboxModule,
    MatBottomSheetModule,
    MatButtonToggleModule,
    MatGridListModule,
    MatProgressSpinnerModule
  ],
})
export class AngularMaterialModule {
  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    iconRegistry.addSvgIcon(
      'ic_dark_sandbox_word_logo',
      sanitizer.bypassSecurityTrustResourceUrl(environment.ic_dark_sandbox_word_logo)
    );
    iconRegistry.addSvgIcon(
      'ic_google_logo_circle',
      sanitizer.bypassSecurityTrustResourceUrl(environment.ic_google_logo_circle)
    );
    iconRegistry.addSvgIcon(
      'ic_microsoft_logo_circle',
      sanitizer.bypassSecurityTrustResourceUrl(environment.ic_microsoft_logo_circle)
    );
    iconRegistry.addSvgIcon(
      'ic_email_verification_logo',
      sanitizer.bypassSecurityTrustResourceUrl(environment.ic_email_verification_logo)
    );

    // product logos
    iconRegistry.addSvgIcon(
      'ic_sandbox_logo_circle',
      sanitizer.bypassSecurityTrustResourceUrl(
        environment.ic_sandbox_logo_circle
      )
    );
  }
}
