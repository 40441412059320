<div class="flex flex-row justify-center">
  <div class="flex flex-col bg-none min-h-screen z-10 flex-grow w-1/2 relative">
    <div class="z-30 mt-[96px] ml-[40px] w-[489px] flex flex-col gap-2">
      <p class="text-headline-semibold">Get API Keys within minutes
        and start testing</p>
      <p class="text-body-2-regular">Integrate with rich, developer friendly APIs </p>
    </div>
    <img src="assets/images/console_ss.png" alt="" class=" absolute z-30 w-2/3 h-[50vh] mt-[332px]">
    <img src="assets/images/tilted_background.png" alt="Tilted Rectangle" class="z-20 absolute w-[1602px] h-[875.18px]">
  </div>
  <div class="flex flex-col justify-start bg-white z-50 px-[32px] py-[40px] w-1/2">
    <!-- sandbox logo -->
    <div>
      <mat-icon svgIcon="ic_dark_sandbox_word_logo" class="w-[102px] h-4"></mat-icon>
    </div>
    <!-- verify email div -->
    <div class="flex flex-col w-[490px] mt-auto mb-auto">
      <div class="flex flex-col gap-12">
        <div class="flex justify-start">
          <mat-icon svgIcon="ic_email_verification_logo" class="h-[48px] w-[46px] cursor-pointer"></mat-icon>
        </div>
        <div class="flex flex-col gap-3">
          <div class="flex justify-start">
            <span class="text-subheading-1-semibold text-navy-blue-800">Check your inbox</span>
          </div>
          <div class="flex justify-start">
            <span class="text-body-1-regular text-medium">We’ve send a verification link to <span
                class="text-body-1-medium text-dark">{{email}}</span> </span>
          </div>
        </div>
        <div class="flex flex-col gap-[16px]">
          <button mat-button
            class="bg-transparent w-[134px] h-[44px] text-navy-blue-800 border-solid border-[1px] border-navy-blue-800 rounded-md">
            <span class="text-body-1-medium">Resend email</span>
          </button>
          <div class="flex justify-start">
            <span class="text-body-1-regular text-dark">Need help? <a href="#" target="_blank"
                class="text-body-1-medium text-navy-blue-600">Visit support</a> or <a href="#" target="_blank"
                class="text-body-1-medium text-navy-blue-600">contact us.</a> </span>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>