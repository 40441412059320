import { HttpBackend, HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { ApiResponse } from '../bean/ApiResponse';
import { AffiliateService } from './affiliate.service';

@Injectable({
  providedIn: 'root',
})
export class OAuthService {
  private authServiceEndpoint: any;
  private httpClient: HttpClient;

  constructor(
    private affiliateService: AffiliateService,
    private cookieService: CookieService,
    private handler: HttpBackend
  ) {
    this.httpClient = new HttpClient(handler);
    this.authServiceEndpoint = environment.auth_services_endpoint;
  }

  authorizeGoogle() {

    let redirect: string = `${environment.login_url}`
    const authorizeParams = new URLSearchParams();
    authorizeParams.set('response_type', 'code');
    authorizeParams.set('client_id', environment.cognito_client_id);
    authorizeParams.set('redirect_uri', redirect);
    authorizeParams.set('identity_provider', 'Google');
    authorizeParams.set('scope', 'profile email openid aws.cognito.signin.user.admin');

    const authorizeUrl = `${environment.cognito_domain}/oauth2/authorize?${authorizeParams.toString()}`;
    window.location.href = authorizeUrl;
  }

  handleAuthCallback(code: string): Observable<ApiResponse<any>> {

    if (!code) {
      throw new Error('No authorization code provided');
    }

    return this.httpClient.get<ApiResponse<any>>(`${environment.auth_services_endpoint}/token?code=${code}`);
  }

  authorizeBroker(requestToken: string, apiKey: string) {
    const url = this.authServiceEndpoint + '/oauth/' + apiKey + '/authorize';

    let params: HttpParams = this.affiliateService.getAffiliateParams();

    let headers = new HttpHeaders().set('Authorization', requestToken);

    return this.httpClient.post(url, null, {
      headers: headers,
      params: params
    });
  }
}
