import { Routes } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { PasswordComponent } from './components/password/password.component';
import { SignupComponent } from './components/signup/signup.component';
import { VerificationComponent } from './components/verification/verification.component';
import { VerifyEmailComponent } from './components/verify-email/verify-email.component';
import { NotLoggedIn } from './services/auth.service';
import { SignupGuard } from './services/cache.service';

export const routes: Routes = [
    { path: '', redirectTo: 'login', pathMatch: "full" },
    {
        path: 'login', canActivate: [NotLoggedIn],
        children: [
            {
                path: '',
                component: LoginComponent,
            },
        ],
    },
    { path: 'signup', canActivate: [NotLoggedIn], component: SignupComponent },
    { path: 'verify', component: VerifyEmailComponent },

    {
        path: 'signup/password',
        component: PasswordComponent,
        canActivate: [SignupGuard]
    },
    {
        path: 'signup/verification',
        component: VerificationComponent,
        canActivate: [SignupGuard]
    },
    { path: '**', redirectTo: 'login' }
];
