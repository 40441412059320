import { email, maxLength, minLength, notEmpty, pattern, prop } from "@rxweb/reactive-form-validators";

export class User {


    @prop()
    '@entity': string = 'in.co.sandbox.user';

    @notEmpty({ message: "Email is required" })
    @email({ message: "Invalid email" })
    email?: string;

    @notEmpty({ message: "First name is required" })
    first_name?: string;

    @notEmpty({ message: "Last name is required" })
    last_name?: string;

    @notEmpty({ message: "Company name is required" })
    business?: string;

    @minLength({ message: "Invalid phone number", value: 10 })
    @maxLength({ message: "Invalid phone number", value: 15 })
    @pattern({ expression: { numeric: /^[0-9]*$/ }, message: 'Invalid phone number' })
    phone?: string;

    @prop()
    country_code?: string;

    @prop()
    password?: string;
}
