import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularMaterialModule } from '../../modules/angular-material/angular-material.module';
import { EntitlementsService } from '../../services/entitlements.service';
import { SnackbarService } from '../../services/snackbar.service';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.css'],
  standalone: true,
  imports: [AngularMaterialModule]
})
export class VerifyEmailComponent {

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private entitlementsService: EntitlementsService,
    private snackBarService: SnackbarService
  ) { }
  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params) => {
      // This occurs when user comes from invited route.

      const email = decodeURIComponent(params['email'])
      const code = params['code']
      const redirect = params['redirect']

      if (email && code) {
        this.entitlementsService.verifySignup(email, code).subscribe({
          next: (response: any) => {
            this.snackBarService.openSnackBar('Email successfully verified', undefined);

            if (!redirect || redirect == "undefined") {
              this.router.navigate(['/login']);
            }
            else {
              this.router.navigate(['/login'], {
                queryParams: {
                  redirect: redirect
                }
              })
            }
          },
          error: (err) => {
            this.snackBarService.openSnackBar('Email verification failed', undefined);
            console.log(err);
            this.router.navigate(['signup'], {
              queryParams: {
                email: email
              }
            });
          }
        });
      }

    })

  }
}
