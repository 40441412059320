import { ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';
import { provideAnimations } from '@angular/platform-browser/animations';
import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { RIVE_FOLDER } from 'ng-rive';
import { ICON_REGISTRY_PROVIDER } from '@angular/material/icon';
import { provideHttpClient, withInterceptorsFromDi, withFetch } from '@angular/common/http';
import { GoogleInitOptions, GoogleLoginProvider, MicrosoftLoginProvider, SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';


export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideClientHydration(),
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi(), withFetch()),
    ICON_REGISTRY_PROVIDER,
    {
      provide: RIVE_FOLDER,
      useValue: "/assets/animations"
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: MicrosoftLoginProvider.PROVIDER_ID,
            provider: new MicrosoftLoginProvider("752b227d-a241-4445-8fe7-ea6e96a2b3f3", {
              redirect_uri: 'http://localhost:4200',
              logout_redirect_uri: 'http://localhost:4200/logout'
            })
          }
        ],
      } as SocialAuthServiceConfig,
    }, provideAnimationsAsync()
  ]
};
