import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SharedService {
    redirectUrl: BehaviorSubject<any>;

    constructor() {
        this.redirectUrl = new BehaviorSubject<any>(null);
    }

    getRedirectUrl() {
        return this.redirectUrl.asObservable();
    }

    setRedirectUrl(redirectUrl: any) {
        this.redirectUrl.next(redirectUrl);
    }
}


