import { Injectable } from '@angular/core';
import { User } from '../bean/user';
import { BehaviorSubject, Observable } from 'rxjs';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class CacheService {
    formData: BehaviorSubject<any | User>;
    constructor() {
        this.formData = new BehaviorSubject<any | User>(null);
    }

    getFormData() {
        return this.formData.asObservable();
    }

    setFormData(formData: User | any) {
        this.formData.next(formData);
    }

    clearDataStream() {
        this.formData.unsubscribe();
        this.formData = new BehaviorSubject<any>(null);
    }
}

@Injectable({
    providedIn: 'root'
})
export class SignupGuard {

    user: User | undefined;
    constructor(private cacheService: CacheService, private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

        this.cacheService.getFormData().subscribe((user) => {
            this.user = user;
        })
        if (this.user?.email && this.user.business && this.user.first_name && this.user.last_name) {
            return true;
        }
        else {
            const emailQueryParam = route.queryParams['email'];
            if (emailQueryParam) {
                return true;
            } else {
                this.router.navigate(['/signup']);
                return false;
            }
        }
    }
}
