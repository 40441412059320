import { GoogleSigninButtonModule, MicrosoftLoginProvider, SocialAuthService, SocialLoginModule } from '@abacritt/angularx-social-login';
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { RxFormBuilder, RxReactiveFormsModule, RxwebValidators } from '@rxweb/reactive-form-validators';
import { RiveModule } from 'ng-rive';
import { environment } from '../../../environments/environment';
import { AngularMaterialModule } from '../../modules/angular-material/angular-material.module';
import { AuthService } from '../../services/auth.service';
import { EntitlementsService } from '../../services/entitlements.service';
import { OAuthService } from '../../services/oauth.service';
import { SessionCookieService } from '../../services/session-cookie.service';
import { SharedService } from '../../services/shared.service';
import { SnackbarService } from '../../services/snackbar.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  standalone: true,
  imports: [AngularMaterialModule, RouterModule, CommonModule, SocialLoginModule, GoogleSigninButtonModule, RiveModule, ReactiveFormsModule, RxReactiveFormsModule]
})
export class LoginComponent {
  loginForm: FormGroup;
  email: any;
  isUser: boolean = false;
  redirectUrl: string | undefined;
  saveForm: boolean = false;
  showPassword: boolean = false;
  googleLogin: boolean = false;
  incorrectPassword: boolean = false;
  googleError: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private entitlementsService: EntitlementsService,
    private socialAuthService: SocialAuthService,
    private snackBarService: SnackbarService,
    private authService: AuthService,
    private sharedService: SharedService,
    private oauthService: OAuthService,
    private sessionCookieService: SessionCookieService,
    private fb: RxFormBuilder
  ) {
    this.loginForm = this.fb.group({
      email: ['', [RxwebValidators.notEmpty({ message: 'Email is required' }), RxwebValidators.email({ message: 'Invalid email' })]],
      password: ['', [RxwebValidators.notEmpty({ message: 'Password is required' }), RxwebValidators.maxLength({ value: 256, message: "Invalid password" })]],
    });
    this.sharedService.getRedirectUrl().subscribe((redirectUrl) => {
      this.redirectUrl = redirectUrl;
    })
    this.activatedRoute.queryParams.subscribe((params) => {
      if (!this.redirectUrl)
        this.redirectUrl = params['redirect'] ? btoa(params['redirect']) : undefined;
    });
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params) => {
      const code = params['code'];
      if (code) {
        this.saveForm = true;
        this.oauthService.handleAuthCallback(code).subscribe({
          next: (response) => {
            console.log(response.data.access_token);
            this.authService.setSessionToken(response.data.access_token);
            this.authService.setRefereshToken(response.data.refresh_token);
            if (this.redirectUrl) {
              // console.log(this.redirectUrl);
              window.location.href = window.atob(this.redirectUrl);
            }
            else {
              window.location.href = environment.sandbox_console_host;
            }
            this.saveForm = false;
          },
          error: (error) => {
            this.saveForm = false;
            this.snackBarService.openSnackBar("Failed to Login with Google", undefined);
          }
        });
        const url = window.location.protocol + '//' + window.location.host + window.location.pathname;
        window.history.replaceState({}, document.title, url);
      }
    }
    )
  }

  verify() {
    if (this.loginForm.valid && this.loginForm.controls['email'].value) {
      this.saveForm = true;

      this.entitlementsService.login(this.loginForm.controls['email'].value, this.loginForm.controls['password'].value)
        .subscribe({
          next: (response: any) => {
            this.authService.setSessionToken(response.data.access_token);
            this.authService.setRefereshToken(response.data.refresh_token);
            if (this.redirectUrl) {
              window.location.href = atob(this.redirectUrl.trim());
            }
            else {
              console.log(this.redirectUrl)
              window.location.href = environment.sandbox_console_host;
            }
            this.saveForm = false;
          },
          error: (err) => {
            if (err.error.message) {
              if (err.error.message.includes('Bad credentials')) {
                this.incorrectPassword = true;
              }
              else {
                this.snackBarService.openSnackBar(err.error.message, undefined);
              }
            };
            this.saveForm = false;
          },
        });
    }
    else {
      this.loginForm.controls['email'].markAsTouched();
      this.loginForm.controls['password'].markAsTouched();
    }
  }

  navigateToForgotPassword() {
    this.router.navigate(['/set-password/verify'], {
      queryParams: {
        email: this.email
      },
    });
  }

  loginWithGoogle() {
    this.oauthService.authorizeGoogle();
  }

  loginWithMicrosoft() {
    this.socialAuthService.signIn(MicrosoftLoginProvider.PROVIDER_ID)
  }

}

