import { Component } from '@angular/core';
import { ActivatedRoute, RouterOutlet } from '@angular/router';
import { SessionCookieService } from './services/session-cookie.service';
import { SharedService } from './services/shared.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent {
  title = 'in-co-sandbox-accounts';
  constructor(private sessionCookieService: SessionCookieService, private sharedService: SharedService, private activatedRoute: ActivatedRoute) {

    this.activatedRoute.queryParams.subscribe((params) => {

      if (params['utm_source']) {
        this.sessionCookieService.setCookie("UTM_SOURCE", params['utm_source']);
      }

      if (params['utm_medium']) {
        this.sessionCookieService.setCookie("UTM_MEDIUM", params['utm_medium']);
      }

      if (params['utm_name']) {
        this.sessionCookieService.setCookie("UTM_NAME", params['utm_name']);
      }

      if (params['utm_content']) {
        this.sessionCookieService.setCookie("UTM_CONTENT", params['utm_content']);
      }

      if (params['url']) {
        this.sharedService.setRedirectUrl(params['url']);
      }
      if (params['invite']) {
        // console.log(params['invite']);
        const redirectUrl = atob((params['invite'].split('=')[0])).split('redirect=')[1]
        // console.log(redirectUrl);
        this.sharedService.setRedirectUrl(btoa(redirectUrl));
      }
    });
  }

  ngOnInit() {
  }
}
